@import "~bootstrap/scss/bootstrap";

$primary-color: rgba(0, 70, 249, 255);
$secondary-color: rgb(37, 150, 190);
$background-color: rgb(7, 9, 21);
$background-lighter: rgba(21, 23, 34, 0.99);
$input-color: rgba(46, 48, 59, 255);
$highlight-color: rgba(255, 255, 255, 255);
$warning-color: rgba(255, 0, 0, 255);

html, body {
  font-family: arial, serif;
  background-color: $background-color;
  color: $highlight-color;
}

a {
  text-decoration: none;
}

input, textarea {
  background-color: $input-color !important;
  color: $highlight-color !important;
}

.light-bg {
  background-color: $background-lighter;
}

.tab-header {
  margin: 1em 0 0 0.5em;
  padding: 0.5em;
  background-color: $secondary-color;
  cursor: pointer !important;
  text-align: center;
  border-radius: 0 1em 0 0;
}

.tab-header-selected {
  background-color: $primary-color;
}

#errors {
  width: 100%;
  height: 2.5em;
  color: $warning-color;
  text-align: center;
}

// this is default in bootstrap and looks bizarre particularly on mobile
// the right edge just ends up with some random padding o.O
.row, .container-xxl {
  --bs-gutter-x: 0em;
}

.opening-text {
  padding: 1em;
}
